import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "reactstrap";
import Contact from "../components/contact";

import Layout from "../components/layout";

const SecondPage = () => (
  <Layout title={"Prop Maker - About Us"}>
    <Container>
      <div className="about-us-content py-5 text-center">
        <h1>ABOUT US</h1>
        <p>
          PropMaker.com uses highly skilled propmakers to fabricate custom props
          for trade show booths, marketing and advertising campaigns, events or
          for anything a custom prop is needed.
        </p>
      </div>
      <Contact />
    </Container>
  </Layout>
);

export default SecondPage;
